var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box form-box has-background-white" }, [
    _c(
      "div",
      { staticClass: "enso-form" },
      [
        _c("enso-form", {
          ref: "form",
          attrs: { "disable-state": "" },
          on: { ready: _vm.init, submit: _vm.submit },
          scopedSlots: _vm._u([
            {
              key: "pin_id",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(" " + _vm._s(_vm.i18n(props.field.label)) + " ")
                      ]),
                      _c("pin-select", {
                        staticClass: "control",
                        attrs: { value: props.field.value },
                        on: { input: _vm.inputPins }
                      }),
                      props.errors.errors.hasOwnProperty("pin_id")
                        ? _c("p", { staticClass: "help is-danger" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.i18n(props.errors.errors.pind_id[0])
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "none" } },
                    [_c("form-field", _vm._b({}, "form-field", props, false))],
                    1
                  )
                ]
              }
            },
            {
              key: "lat",
              fn: function() {
                return [
                  _c(
                    "div",
                    [
                      _c("b-gmap-autocomplete", {
                        on: { place_changed: _vm.setPlace }
                      }),
                      _vm.lat && _vm.lng
                        ? _c(
                            "GmapMap",
                            {
                              staticStyle: { width: "100%", height: "400px" },
                              attrs: {
                                center: { lat: _vm.lat, lng: _vm.lng },
                                zoom: 14
                              }
                            },
                            [
                              _c("GmapMarker", {
                                attrs: {
                                  position: { lat: _vm.lat, lng: _vm.lng },
                                  draggable: true
                                },
                                on: {
                                  dragend: function($event) {
                                    return _vm.updateCoordinates($event.latLng)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "image",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "columns custom-section-wrapper is-multiline"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-3 section-description" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                " " + _vm._s(_vm.i18n(props.field.label)) + " "
                              )
                            ])
                          ]),
                          _c(
                            "b-field",
                            [
                              _c(
                                "b-upload",
                                {
                                  attrs: {
                                    "drag-drop": "",
                                    expanded: "",
                                    accept: "image/*"
                                  },
                                  on: { input: _vm.setImage },
                                  model: {
                                    value: _vm.new_image,
                                    callback: function($$v) {
                                      _vm.new_image = $$v
                                    },
                                    expression: "new_image"
                                  }
                                },
                                [
                                  _c("section", { staticClass: "section" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content has-text-centered"
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "upload",
                                                size: "is-large"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            "Drop your files here or click to upload"
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-3" },
                        [
                          _vm.image || _vm.new_image
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "remove_tag",
                                  attrs: {
                                    closable: "",
                                    "aria-close-label": "Remove"
                                  },
                                  on: { close: _vm.resetImage }
                                },
                                [_vm._v(" Remove ")]
                              )
                            : _vm._e(),
                          _vm.new_image
                            ? _c("img", {
                                attrs: { src: _vm.$displayImage(_vm.new_image) }
                              })
                            : _vm._e(),
                          _vm.image && !_vm.new_image
                            ? _c("img", {
                                attrs: { src: "/storage/" + _vm.image }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }