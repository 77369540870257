<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form"
                       @ready="init"
                       @submit="submit"
                       disable-state>
                <template v-slot:pin_id="props">
                    <div class="field">
                        <div class="label">
                            {{ i18n(props.field.label) }}
                        </div>
                        <pin-select :value="props.field.value" class="control" @input="inputPins"/>
                        <p class="help is-danger"
                           v-if="props.errors.errors.hasOwnProperty('pin_id')">
                            {{ i18n(props.errors.errors.pind_id[0]) }}
                        </p>
                    </div>
                    <div style="display:none">
                        <form-field v-bind="props"/>
                    </div>
                </template>
                <template v-slot:lat>
                    <div>
                        <b-gmap-autocomplete @place_changed="setPlace"/>
                        <GmapMap
                            v-if="lat && lng"
                            :center="{lat, lng}"
                            :zoom="14"
                            style="width: 100%; height: 400px">
                            <GmapMarker
                                :position="{lat, lng}"
                                :draggable="true"
                                @dragend="updateCoordinates($event.latLng)"/>
                        </GmapMap>
                    </div>
                </template>
                <template v-slot:image="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-3 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                            <b-field>
                                <b-upload v-model="new_image" drag-drop expanded @input="setImage" accept="image/*">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-large"/>
                                            </p>
                                            <p>Drop your files here or click to upload</p>
                                        </div>
                                    </section>
                                </b-upload>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-tag v-if="image || new_image"
                                   closable
                                   aria-close-label="Remove"
                                   @close="resetImage"
                                   class="remove_tag">
                                Remove
                            </b-tag>
                            <img v-if="new_image"
                                :src="$displayImage(new_image)">
                            <img v-if="image && !new_image"
                                 :src="'/storage/' + image">
                        </div>
                    </div>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { FormField } from '@enso-ui/bulma';
import PinSelect from '@components/pins/PinSelect.vue';
import LocationMixin from '@mixins/location/index';
import { EnsoForm } from '@enso-ui/forms/bulma';
import BGmapAutocomplete from '@components/ui/BGmapAutocomplete.vue';

export default {
    name: 'Edit',
    components: {
        EnsoForm,
        BGmapAutocomplete,
        PinSelect,
        FormField,
    },
    mixins: [LocationMixin],
    inject: ['i18n', 'route', 'toastr', 'errorHandler'],
    data: () => ({
        pinId: null,
        image: null,
        new_image: null,
        remove_image: false,
        ready: false,
    }),
    computed: {
        dirty() {
            return this.ready
                ? this.form.dirty
                : {};
        },
        form() {
            return this.ready
                ? this.$refs.form.$refs.form
                : {};
        },
    },
    methods: {
        init() {
            this.ready = true;
            this.lat = parseFloat(this.form.field('lat').value);
            this.lng = parseFloat(this.form.field('lng').value);
            this.pinId = this.form.field('pin_id').value;
            if (this.form.field('image').value) {
                this.image = this.form.field('image').value;
            }
        },
        inputPins(pin) {
            if (pin) {
                this.pinId = pin.id;
                this.form.field('pin_id').value = pin.id;
            }
        },
        setImage() {
            this.form.field('image').value = this.image;
            this.remove_image = false;
        },
        resetImage() {
            this.new_image = null;
            this.image = null;
            this.remove_image = true;
        },
        submit(val) {
            const formData = new FormData();
            if (this.remove_image) {
                formData.append('remove_image', 1);
            }
            if (this.new_image) {
                formData.append('image', this.new_image);
                formData.append('remove_image', 0);
            }
            formData.append('_method', 'PATCH');
            axios.post(this.route('locations.image.update', val.location.id), formData)
                .catch(this.errorHandler);
        },
    },
};
</script>
